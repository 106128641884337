<template>
  <configuration-table
      :search="search"
      :itemsPerPpage="itemsPerPpage"
  />
</template>
<script>
import ConfigurationTable from "../components/admin/ConfigurationTable"
import {mapActions, mapState} from "vuex";
export default {
  components:{
    ConfigurationTable
  },
  async created(){
    await this.retriveConfigurations();
    this.loading = false;
  },
  data(){
    return {
      search: {
        searchWord: ""
      },
      itemsPerPpage: 10
    };
  },
  computed: {
    ...mapState('configuration', {
      configurations: "configurations"
    })
  },

  methods: {
    ...mapActions('configuration', ['retriveConfigurations'])
  }
}
</script>
