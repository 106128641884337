<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%">
   <div class="pa-3 my-border">
    <v-card class="ma-0 pa-0 mb-3 elevation-0 white" style="max-height:100%">
      <div class=" my-border customoffwhite">
      <v-card-title>
        <span class="font-weight-medium text-left text--secondary">{{$t('Select Table')}}</span>
      </v-card-title>
      <v-card-text>
        <v-autocomplete
            :items="listTable"
            :label="$t('Select Table')"
            :placeholder="$t('Select Table')"
            v-model="reqTable"
            outlined
            dense
            hide-details
            clearable
        ></v-autocomplete>
      </v-card-text>
      </div>
    </v-card>

    <v-card class="ma-0 pa-0 mb-3 elevation-0 white" style="max-height:100%"  v-if="reqTable">
      <div class=" my-border customoffwhite">
        <v-card-title>
          <span class="font-weight-medium text-left text--secondary">{{reqTable}}</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
              :items="getTables(reqTable)"
              :label="$t('Select Table')"
              :placeholder="$t('Select Table')"
              v-model="reqSubTable"
              item-value="LookUpName"
              item-key="LookUpKey"
              outlined
              dense
              hide-details
              clearable
          ></v-autocomplete>
        </v-card-text>
      </div>
    </v-card>
    <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%"  v-if="configurations && reqSubTable !== 'Choose Table' && reqSubTable !== 0">
    <div class=" my-border">
      <v-card-title class="py-0 my-0 customlightgray">
        <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="5" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{reqSubTable}}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                            class="custom-color-accent pa-1">{{getTables( reqSubTable, $vuetify.lang.current ).length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="7" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="addItem" v-if="$checkPermission('AdminMenu.Read')">{{$t("New Configuration")}}</v-btn>
            </v-col>
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="1000px">
        <v-card class="customlightgray">
          <v-card-title class="justify-center ma-0 gray">
            <span class="font-weight-light">{{ formTitle }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <v-form  v-on:submit.prevent ref="form"
              v-model="valid"
              lazy-validation >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpName" readonly :rules="[rules.required]" filled outlined dense :label="$t('LookUp Name')" :placeholder="$t('LookUp Name')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpKey" :rules="[rules.required]" outlined dense :label="$t('LookUp Key')" :placeholder="$t('LookUp Key')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpStringE" :rules="[rules.required]" outlined dense :label="$t('String English')" :placeholder="$t('String English')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpStringF" :rules="[rules.required]" outlined dense :label="$t('String French')" :placeholder="$t('String French')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpOther" outlined dense :label="$t('LookUp Status')" :placeholder="$t('LookUp Status')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="requestConfiguration.LookUpMoney" outlined dense :label="$t('Money')" :placeholder="$t('Money')"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="close = dialog = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t("delete")}}</v-card-title>
          <v-card-text>{{$t("Are you sure you want to delete this configuration?")}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteConfig()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
          :headers="headers"
          :items="getTables( reqSubTable, $vuetify.lang.current )"
          class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
          :search="search.searchWord"
          :loading="loading" 
          :loading-text="$t('Loading')"
          @click:row="handleClick"
          :footer-props="{
            'items-per-page-text':$t('rowsperpage')
          }"
          sort-by="id"
      >
      <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <v-spacer></v-spacer>
        <template v-slot:[`item.LookUpStringE`] ="{item}">
          <div class="text-truncate" style="max-width: 200px;">{{ item.LookUpStringE }}</div>
        </template>
        <template v-slot:[`item.LookUpStringF`] ="{item}">
          <div class="text-truncate" style="max-width: 200px;">{{ item.LookUpStringF }}</div>
        </template>
         <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
  </v-card>
  </div>
  </v-card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from "moment";
import lodash from "lodash";
export default {
  props: ["search", "itemsPerPpage","customFilter"],

  data() {
    return {
      valid: true,
      rules: {
          required: value => !!value || this.$t('required'),
      },
      tableColumn:"",
      tableEntries:false,
      listTable:[
        "Application Table",
        "System Table"
      ],
      reqTable:0,
      reqSubTable:0,
      LookUpName:'',
      editFlag: false,
      deletedItem:"",
      assign: false,
      selected: null,
      selectedItem: null,
      disabled: true,
      headers: [
        {
          text: "ID",
          align: "left",
          sortable: true,
          value: "LookUpTableId"
        },
        { text: this.$t("LookUp Name"), value: "LookUpName",filter: this.nameFilter },
        { text: this.$t("LookUp Key"), value: "LookUpKey" },
        { text: this.$t("String English"), value: "LookUpStringE"},
        { text: this.$t("String French"), value: "LookUpStringF"},
        { text: this.$t("LookUp Status"), value: "LookUpOther"},
        { text: this.$t("Money"), value: "LookUpMoney"},
        { text: "", value: "action", sortable: false }
      ],
      actions: [
        { title: "Edit" },{title: "delete"}
      ],
      dialog: "",
      error: "",
      dialogDelete: false,
      lookupnames: []
    };
  },
  async beforeCreate() {
    await this.$store.dispatch("configuration/retriveConfigurations");
  },
  updated() {
    this.lookupnames = Object.keys(lodash.groupBy(this.configurations,'LookUpName'));
  },
  computed: {
    ...mapState('configuration', {
      configurations: 'configurations',
      loading:'loading',
      requestConfiguration:'requestConfiguration'
    }),
    formTitle() {
      return !this.editFlag? this.$t("newlookup") : this.$t("editlookup");
    },
  },
  watch: {
    dialog( lastVal ){
      if( ! lastVal ){
        this.emptyConfiguration();
      }
    },
    reqSubTable(val){
      this.requestConfiguration.LookUpName=val;
    },
  },
  methods: {
    addItem(){
      this.dialog = true;
      this.editFlag = false;
      this.emptyConfiguration();
    },
    handleClick(data){
      this.tableColumn = data.LookUpKey;
    },
    getTables(tables , lang = ""){
      let cTable = "";
      switch ( tables ) {
        case 'Application Table':
          cTable = 'AppTable';
          break;
        case 'System Table':
          cTable = 'SysTable';
          break;
        default:
          cTable = '';
            break;
      }
      if(lang){
        if(lang === 'en')
          return this.configurations.filter( filter => filter.LookUpName === tables );
        if(lang === 'fr')
          return this.configurations.filter( filter => filter.LookUpName === tables );
      }else{
        return this.configurations.filter( filter => filter.LookUpName === cTable ).map( item => {
          let arr = [];
          arr['text'] = this.$vuetify.lang.current === 'en' ? item.LookUpKey : item.LookUpKey;
          arr['value'] = item.LookUpKey;
          return  arr;
        })
      }
    },
    getLookup( column ){
      return this.configurations.filter( filter => filter.LookUpName === column ).map( item => {
        let arr = [];
        arr['text'] = this.$vuetify.lang.current === 'en' ? item.LookUpStringE : item.LookUpStringF;
        arr['value'] = item.LookUpKey;
        return  arr;
      })
    },
    ...mapActions('configuration',['addConfiguration', 'updateConfiguration','setConfigurationItem','setFlagEdit', 'removeConfiguration', 'emptyConfiguration']),
    advanceSearch(){
      this.dialog = true;
    },
    nameFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.LookUpName) {
        return true;
      }
      // Check if the current loop value (The dessert name)
      // partially contains the searched word.
      return value.toLowerCase() === this.LookUpName.toLowerCase();
    },
    formatDate(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    save(){
      if(this.editFlag){
        if(this.$refs.form.validate()){
          this.updateConfiguration(this.requestConfiguration).then(()=>{
            let color="dark";
            let alert="itemUpdatedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
          this.editFlag = false;
        }
        else
          return
      }else{
        if(this.$refs.form.validate()){
          this.addConfiguration(this.requestConfiguration).then(()=>{
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          })
        }
        else
          return
      }
      this.dialog = false;
    },
    editItem(item){
      this.dialog = true;
      this.editFlag = true;
      this.setConfigurationItem(item);
    },
    deleteItem(item){
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteConfig(){
      this.removeConfiguration( this.deletedItem.LookUpTableId ).then(()=>{
      this.dialogDelete = false;
        let color="dark";
        let alert="itemRemovedAlert";
        this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
      })
    },
    close() {
      this.assign = false;
      this.selected = null;
      this.loading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.row {
  cursor: pointer;
}
</style>